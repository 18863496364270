import React, { Component } from 'react';
import { I18n } from '../../Translations/I18n';
import InfoBox from '../../InfoBox/InfoBox';
import RadioButtonGroup from '../../RadioButtonGroup/RadioButtonGroup';
import BaseMaterial from './BaseMaterial/BaseMaterial';
import BaseColour from './BaseColour/BaseColour';
import BaseShape from './BaseShape/BaseShape';
import BaseHandle from './BaseHandle/BaseHandle';
import styles from './TextBase.module.scss';
import PropTypes from 'prop-types';

export default class TextBase extends Component {
  state = {
    base: [
      {
        id: 'baseMaterial',
        title: 'Materiał'
      },
      {
        id: 'baseColour',
        title: 'Kolor'
      },
      {
        id: 'baseShape',
        title: 'Kształt'
      },
      {
        id: 'baseHandle',
        title: 'Uchwyt'
      }
    ],
    displaySubComponent: 'baseMaterial'
  };

  selectComponent = component => {
    this.setState({
      displaySubComponent: component
    });
  };

  render() {
    const {
      baseColour,
      baseHandle,
      baseMaterial,
      baseShape
    } = this.props.baseOptions;

    const displaySubComponent = this.state.displaySubComponent;
    const renderSubComponent = () => {
      switch (displaySubComponent) {
        case 'baseMaterial':
          return (
            <BaseMaterial
              data={this.props.data.materials}
              state={baseMaterial}
              method={this.props.handleRadioButtonChange}
            />
          );
        case 'baseColour':
          return (
            <BaseColour
              data={this.props.data.colours}
              material={baseMaterial}
              state={baseColour}
              method={this.props.handleBaseColourChange}
            />
          );
        case 'baseShape':
          return (
            <BaseShape
              data={this.props.data.shape}
              state={baseShape}
              method={this.props.handleRadioButtonChange}
            />
          );
        case 'baseHandle':
          return (
            <BaseHandle
              data={this.props.data.handle}
              state={baseHandle}
              method={this.props.handleRadioButtonChange}
            />
          );
        default:
          return (
            <BaseMaterial
              data={this.props.data.materials}
              state={baseMaterial}
              method={this.props.handleRadioButtonChange}
            />
          );
      }
    };

    const baseSubComponents = selectedBase => {
      if (selectedBase === 'addBase') {
        const baseOptions = this.state.base.map(el => {
          const isActive =
            this.state.displaySubComponent === el.id
              ? `${styles.item} ${styles.active}`
              : styles.item;

          return (
            <li
              className={isActive}
              id={el.id}
              key={el.id}
              onClick={e => {
                this.selectComponent(e.currentTarget.id);
              }}
            >
              <h3 className={styles.heading}>{el.title}</h3>
            </li>
          );
        });
        return (
          <React.Fragment>
            <nav className={styles.nav}>
              <ul className={styles.list}>{baseOptions}</ul>
            </nav>
            <div className={styles.content}>{renderSubComponent()}</div>
          </React.Fragment>
        );
      } else {
        return (
          <I18n>
            {ctx => (
              <div className={styles.description}>
                <InfoBox ctx={ctx('base.note_main')} />
              </div>
            )}
          </I18n>
        );
      }
    };

    return (
      <I18n>
        {ctx => (
          <div className="Sidebar__content">
            <div className="Sidebar__wrapper">
              <h2 className="Sidebar__heading">{ctx('base.heading')}</h2>
              <RadioButtonGroup
                data={this.props.data.options}
                name="selectedBase"
                state={this.props.selectedBase}
                method={this.props.handleRadioButtonChange}
              />
              {baseSubComponents(this.props.selectedBase)}
            </div>
          </div>
        )}
      </I18n>
    );
  }
}

TextBase.propTypes = {
  data: PropTypes.object,
  baseOptions: PropTypes.objectOf(PropTypes.string),
  selectedBase: PropTypes.string,
  handleRadioButtonChange: PropTypes.func,
  handleBaseColourChange: PropTypes.func
};
