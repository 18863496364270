import useWindowDimensions from '../windowDimentions.js';

const adjustCableHeight = () => {
  const { height, width } = useWindowDimensions();
  let style = { paddingBottom: '' };
  if (width > 1240 && height <= 750) {
    style = { paddingBottom: '45vh' };
  }

  if (width > 1240 && height <= 700) {
    style = { paddingBottom: '50vh' };
  }
  if (width < 768 && height >= 1024) {
    style = { paddingTop: '20vh' };
  }
  return style;
};

export default adjustCableHeight;
