import React from 'react';
import { I18n } from '../../Translations/I18n';
import ButtonText from '../../ButtonText/ButtonText';
import styles from './TextFont.module.scss';
import PropTypes from 'prop-types';

const TextFont = ({ data, selectedFont, handleFontChange }) => {
  const fontContent = data.fonts.map(font => {
    const isCurrent = font.fontFamily === selectedFont;
    const keyFont = font.fontLabel.toLowerCase();

    return (
      <li key={keyFont} className={styles.item}>
        <ButtonText
          component="font"
          name="selectedFont"
          state={font.fontFamily}
          text={font.fontLabel}
          isCurrent={isCurrent}
          method={handleFontChange}
        />
      </li>
    );
  });

  return (
    <I18n>
      {ctx => (
        <div className="Sidebar__content">
          <div className="Sidebar__wrapper">
            <h2 className="Sidebar__heading">{ctx('font.heading')}</h2>
            <ul className={styles.list}>{fontContent}</ul>
          </div>
        </div>
      )}
    </I18n>
  );
};

TextFont.propTypes = {
  data: PropTypes.object,
  selectedFont: PropTypes.string,
  handleColourChange: PropTypes.func
};

export default TextFont;
