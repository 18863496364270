import React from 'react';
import { Field } from 'formik';
import { I18n } from '../../../Translations/I18n';
import styles from './FieldWrapper.module.scss';
import classnames from 'classnames';

const ErrorMessage = ({ error }) =>
  error ? <div className={styles.error}>{error}</div> : null;

const FieldWrapper = ({ type, field, error }) => (
  <I18n>
    {ctx => (
      <div className={styles.fieldWrapper}>
        <Field
          type={type || 'text'}
          className={classnames(styles.input, error && styles.invalid)}
          name={field}
          placeholder={ctx(`form.${field}`)}
        />
        <ErrorMessage error={error} />
      </div>
    )}
  </I18n>
);

export default FieldWrapper;
