import React, { Component } from 'react';
import styles from './Stage.module.scss';
import sidebarData from './stageData.js';
import Background from './Background/Background';
import Header from './Header/Header';
import Preview from './Preview/Preview';
import Sidebar from './Sidebar/Sidebar';
import amatic from '../fontsData/amatic';
import cmu from '../fontsData/cmuTypewriter';
import havana from '../fontsData/havana';
import pecita from '../fontsData/pecita';
import quicksand from '../fontsData/quicksand';
import shadows from '../fontsData/shadows';
import tamoro from '../fontsData/tamoro';
import learningcurve from '../fontsData/learningCurve';
import { TranslationsProvider } from './Translations/TranslationsProvider';

export default class Stage extends Component {
  state = this.initialState;

  get initialState() {
    return {
      currentTab: 'text',
      input: '',
      lightOn: false,
      visibleForm: false,
      confirmationForm: false,
      serverErrorResponse: false,
      resetStates: false,
      selectedFont: 'Quicksand',
      selectedSize: 'small',
      selectedColour: {
        neonValue: 'superPink',
        neonLabel: 'różowy',
        pipeLabel: 'biały'
      },
      selectedCable: 'black',
      selectedBase: 'noBase',
      baseMaterial: 'acrylic',
      baseColour: 'czarny',
      baseShape: 'rectangle',
      baseHandle: 'handleWithPower',
      neonOrder: {
        neonWidth: 0,
        neonHeight: 0,
        neonPrice: 0,
        delivery: 0
      },
      importedFont: quicksand,
      importedFonts: {
        amatic,
        cmu,
        havana,
        pecita,
        quicksand,
        shadows,
        tamoro,
        learningcurve
      }
    };
  }

  handleTabChange = currentTab => {
    this.setState({
      currentTab
    });
  };

  handleInputChange = input => {
    // negated characters classes: [^ (...) ]
    const regex = /[^a-z0-9ęóąśłżźćń!@#$&(),<>/?\n \-+.]/gi;
    const newInput = input.replace(regex, ' ');
    this.setState(
      {
        input: newInput
      },
      () => {
        if (this.state.input.length) {
          this.calculatePrice(newInput);
        } else {
          this.getSummary(0, 0, 0);
        }
      }
    );
  };

  handleToggleChange = target => {
    if (target === 'confirmationForm' || target === 'serverErrorResponse') {
      this.setState({
        [target]: !this.state[target]
      });
    } else {
      const name = target.name;
      this.setState({
        [name]: !this.state[name]
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.resetStates !== prevState.resetStates) {
      this.resetState();
    }
  }

  resetState = () => {
    this.setState(this.initialState);
  };

  handleColourChange = colour => {
    this.setState({
      selectedColour: {
        ...this.state.selectedColour,
        neonValue: colour.value,
        neonLabel: colour.getAttribute('data-label'),
        pipeLabel: colour.getAttribute('data-pipe')
      }
    });
  };

  handleBaseColourChange = colour => {
    this.setState({
      baseColour: colour.getAttribute('data-label')
    });
  };

  handleFontChange = target => {
    const convertValue = target.value.toLowerCase();
    this.setState(
      {
        [target.name]: target.value,
        importedFont: this.state.importedFonts[convertValue]
      },
      () => {
        this.calculatePrice(this.state.input);
      }
    );
  };

  handleRadioButtonChange = target => {
    const name = target.name;
    const value = target.value;
    this.setState(
      {
        [name]: value
      },
      () => {
        if (name === 'selectedBase' || name === 'selectedSize') {
          this.calculatePrice(this.state.input);
        }
      }
    );
    if (name === 'baseMaterial') {
      this.setMaterialColour(value);
    }
  };

  setMaterialColour = value => {
    if (value === 'wood') {
      this.setState({
        baseColour: 'surowa sosna'
      });
    } else if (value === 'aluminium') {
      this.setState({
        baseColour: 'czarny (9005)'
      });
    } else {
      this.setState({
        baseColour: 'czarny'
      });
    }
  };

  getSummary = (height, width, price, delivery) => {
    this.setState({
      neonOrder: {
        ...this.state.neonOrder,
        neonHeight: height,
        neonWidth: width,
        neonPrice: price,
        delivery
      }
    });
  };

  calculatePrice = input => {
    const font = this.state.importedFont;
    const size = this.state.selectedSize;
    const base = this.state.selectedBase;
    const innerHeight = 5; // height between rows of texts
    const singlePowerPrice = 310;
    const baseFrame = 10; //additional space in cm for base
    const singleBasePrice = 1500;

    if (!input) {
      return false;
    }

    let wordLength = 0;
    //array for kepping length of each character
    let lettersLength = [];
    //array for summing up lengths of each characters -> width
    let wordsWidth = [];

    const inputArrays = input.split('\n');

    for (let i = 0; i < inputArrays.length; ++i) {
      let charsLength = [];

      //replace empty string (spacebar key) with the word "space"
      let chars = inputArrays[i].split('');
      chars.map(char => {
        if (char === ' ') {
          const space = chars.indexOf(' ');
          chars.splice(space, 1, 'space');
        }
        return false;
      });

      //find each character length - depends on selected font and size
      const findChar = chars.map(char => font[char]);
      findChar.map(letter => {
        const letterLength = Number(letter[size]);
        charsLength.push(letterLength);
        return charsLength;
      });
      //accumulate all lengths
      wordLength = charsLength.reduce((acc, val) => acc + val, 0);
      lettersLength.push(...charsLength);
      wordsWidth.push(wordLength);
    }

    //find max length among words in array (the longest word in row in preview) and round it to first decimal
    let wordWidth = Number(Math.max(...wordsWidth).toFixed(1));

    //price without power and base, depends on amount of characters (lettersLength.length) and chosen size
    const isSmallSize = size === 'small';
    let sizeChoice = isSmallSize ? 10 : 15;
    const fontPrice = isSmallSize ? font.smallPrice : font.bigPrice;
    let wordPrice = lettersLength.length * fontPrice;

    //price with power, if size = small add power every 20 characters, big size -> 15 chars
    let addNewPower = isSmallSize ? 20 : 15;
    let powerPrice =
      Math.ceil(lettersLength.length / addNewPower) * singlePowerPrice;
    wordPrice = wordPrice + powerPrice;

    //calculate word's height: every row (\n) + innerHeight between them
    let area = 0;
    const countRows = input.split('\n').length;
    let wordHeight = sizeChoice;
    if (countRows > 1) {
      sizeChoice *= countRows;
      wordHeight = sizeChoice + innerHeight * (countRows - 1);
    }

    //round height and width of neon
    wordHeight = Math.round(wordHeight);
    wordWidth = Math.round(wordWidth);

    //price for base per 1m^2
    const getBasePrice = () => {
      wordHeight += baseFrame;
      wordWidth += baseFrame;
      area = (wordHeight * wordWidth) / 10000;
      return area * singleBasePrice;
    };

    let totalPrice = wordPrice;
    totalPrice = Number(totalPrice.toFixed(0));
    const hasBase = base === 'addBase';
    if (hasBase) {
      const basePrice = getBasePrice();
      totalPrice += basePrice;
      totalPrice = Number(totalPrice.toFixed(0));
    }

    //delivery price, with base 180zł, without - 120zł
    let delivery = hasBase ? 180 : 120;

    this.getSummary(wordHeight, wordWidth, totalPrice, delivery);
  };

  render() {
    const { importedFont, importedFonts, lightOn, ...rest } = this.state;
    return (
      <div className={styles.container}>
        <TranslationsProvider value={this.state}>
          <Header />
        </TranslationsProvider>
        <Background
          lightOn={this.state.lightOn}
          selectedCable={this.state.selectedCable}
          handleToggleChange={this.handleToggleChange}
        />
        <TranslationsProvider value={this.state}>
          <Sidebar
            data={sidebarData}
            {...rest}
            handleTabChange={this.handleTabChange}
            handleInputChange={this.handleInputChange}
            handleColourChange={this.handleColourChange}
            handleBaseColourChange={this.handleBaseColourChange}
            handleFontChange={this.handleFontChange}
            handleRadioButtonChange={this.handleRadioButtonChange}
            handleToggleChange={this.handleToggleChange}
          />
        </TranslationsProvider>
        <Preview
          data={sidebarData.base.colours}
          previewText={this.state.input}
          lightOn={this.state.lightOn}
          selectedBase={this.state.selectedBase}
          selectedFont={this.state.selectedFont}
          selectedSize={this.state.selectedSize}
          selectedColour={this.state.selectedColour}
          baseMaterial={this.state.baseMaterial}
          baseColour={this.state.baseColour}
          baseShape={this.state.baseShape}
        />
      </div>
    );
  }
}
