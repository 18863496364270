import React from 'react';
import { I18n } from '../../Translations/I18n';
import InfoBox from '../../InfoBox/InfoBox';
import ButtonColour from '../../ButtonColour/ButtonColour';
import styles from './TextColour.module.scss';
import PropTypes from 'prop-types';

const TextColour = ({ data, selectedColour, handleColourChange }) => {
  const { neonValue, neonLabel, pipeLabel } = selectedColour;
  const colourContent = data.colours.map(colour => {
    const isCurrent = colour.value === neonValue;
    return (
      <li className={styles.item} key={colour.label}>
        <ButtonColour
          component={'colour'}
          colour={colour}
          isCurrent={isCurrent}
          method={handleColourChange}
        />
      </li>
    );
  });

  return (
    <I18n>
      {ctx => (
        <div className="Sidebar__content">
          <div className="Sidebar__wrapper">
            <h2 className="Sidebar__heading"> {ctx('colour.heading')} </h2>
            <ul className={styles.list}>{colourContent}</ul>
          </div>
          <InfoBox
            ctx={[ctx('colour.note_on'), ctx('colour.note_off')]}
            state={[neonLabel, pipeLabel]}
          />
        </div>
      )}
    </I18n>
  );
};

TextColour.propTypes = {
  data: PropTypes.object,
  selectedColour: PropTypes.objectOf(PropTypes.string),
  handleColourChange: PropTypes.func
};

export default TextColour;
