import 'babel-polyfill';
import React, { useState, memo } from 'react';
import adjustCableHeight from './adjustCableHeight.js';
import adjustBackgroundHeight from './adjustBackgroundHeight.js';
import BackgroundImageCarousel from './BackgroundImageCarousel/BackgroundImageCarousel';
import classnames from 'classnames';
import styles from './Background.module.scss';
import PropTypes from 'prop-types';
import LightSwitcher from '../LightSwicher/LightSwicher';
import { ReactComponent as BgCable } from '../../assets/images/bg_cable.svg';

const rooms = {
  livingroom: 'Salon',
  kidsroom: 'Pokój dziecięcy',
  bedroom: 'Sypialnia'
};

const getRoomList = () => {
  //initial state (hooks)
  const [roomName, setRoom] = useState('livingroom');

  const roomsItems = Object.entries(rooms).map(([key, value]) => {
    let roomClasses = classnames(
      styles.item,
      key === roomName && styles.active
    );
    return (
      <li className={roomClasses} key={key} onClick={() => setRoom(key)}>
        {value}
      </li>
    );
  });

  const activeRoom = roomName;
  return { activeRoom, roomsItems };
};

const backgroundOverlay = <div className={styles.overlay} />;

const Background = memo(({ lightOn, handleToggleChange, selectedCable }) => {
  const { activeRoom, roomsItems } = getRoomList();
  const cableStyle = adjustCableHeight();

  let backgroundClasses = classnames(
    styles.svgCable,
    styles[`svgCable_${activeRoom}`],
    styles[`svgCable_${selectedCable}`]
  );

  return (
    <div className={styles.container} style={adjustBackgroundHeight()}>
      <BackgroundImageCarousel src={activeRoom} />
      {!lightOn && backgroundOverlay}
      <div className={styles.wrapper}>
        <div className={styles.lightButton}>
          <LightSwitcher
            lightOn={lightOn}
            handleToggleChange={handleToggleChange}
          />
        </div>
        <ul className={styles.list}>{roomsItems}</ul>
        <div className={backgroundClasses} style={cableStyle}>
          <div className={styles.svgCableShadow}>
            <BgCable />
          </div>
          <div className={styles.svgCableDefault}>
            <BgCable />
          </div>
        </div>
      </div>
    </div>
  );
});

Background.defaultProps = {
  lightOn: true,
  selectedCable: 'black'
};

Background.propTypes = {
  lightOn: PropTypes.bool,
  selectedCable: PropTypes.string,
  handleToggleChange: PropTypes.func
};

export default Background;
