import React from 'react';
import { I18n } from '../../../Translations/I18n';
import styles from './FieldHeader.module.scss';

const FieldHeader = ({ heading }) => (
  <I18n>
    {ctx => (
      <legend className={styles.fieldHeading}>{ctx(`form.${heading}`)}</legend>
    )}
  </I18n>
);

export default FieldHeader;
