import React from 'react';
import { I18n } from '../../../Translations/I18n';
import styles from './ResponseHeader.module.scss';

const ResponseHeader = ({ context }) => (
  <I18n>
    {ctx => (
      <div className={styles.header}>
        <h2 className={styles.heading}>{ctx(`response.${context}Heading`)}</h2>
      </div>
    )}
  </I18n>
);

export default ResponseHeader;
