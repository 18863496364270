const axios = require('axios');
const path = require('path')
require('dotenv').config({ path: path.resolve(__dirname, `../.env.${process.env.NODE_ENV}`) });

const mailSeriveHost = process.env.REACT_APP_MAIL_SERVICE || 'localhost:3001';

var axiosInstance = axios.create({
  baseURL: `https://${mailSeriveHost}`
});

module.exports = axiosInstance;
