import React from 'react';
import Icon from '../Icon/Icon';
import styles from './InfoBox.module.scss';

const InfoBox = ({
  content = false,
  ctx = false,
  state = false,
  container = 'container',
  direction = 'row'
}) => {
  const checkCtx = Array.isArray(ctx);
  const checkState = Array.isArray(state);
  let text = ctx;
  let states = state;

  if (!checkCtx) {
    text = [ctx];
  }

  if (!checkState) {
    states = [state];
  }

  const textContent = text.map((el, i) => {
    if (!el) {
      return false;
    }
    return (
      <p className={styles.text} key={i}>
        {el}
      </p>
    );
  });

  const stateContent = states.map((el, i) => {
    if (!el) {
      return false;
    }
    if (el === 'cloud' || el === 'rectangle' || el === 'fontSize') {
      return <Icon src={el} key={`${el}-${i}`} />;
    } else {
      return (
        <p className={styles.text} key={i}>
          {el}
        </p>
      );
    }
  });

  const hasContent = (
    <div className="InfoBox__column--first">{textContent}</div>
  );

  const hasStates = <div className={styles.highlighed}>{stateContent}</div>;

  return (
    <div className={`InfoBox__container ${container}`}>
      <div className={styles[direction]}>
        {textContent[0] !== false && hasContent}
        {stateContent[0] !== false && hasStates}
        <div className="InfoBox__contentWrapper">{content}</div>
      </div>
    </div>
  );
};

export default InfoBox;
