import React from 'react';
import { Field } from 'formik';
import { I18n } from '../../../Translations/I18n';

const FieldCheckbox = ({ name, values, handleCheckboxFormChange }) => (
  <I18n>
    {ctx => (
      <div className="Checkbox__wrapper">
        <Field
          type="checkbox"
          id={name}
          name={name}
          onClick={e => {
            handleCheckboxFormChange(e.currentTarget);
          }}
          value={values[name]}
          checked={values[name]}
        />
        <label htmlFor={name}>{ctx(`form.${name}`)}</label>
      </div>
    )}
  </I18n>
);

export default FieldCheckbox;
