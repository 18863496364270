import React from 'react';
import classnames from 'classnames';
import styles from './Preview.module.scss';
import PropTypes from 'prop-types';

const Preview = ({
  data,
  lightOn,
  previewText,
  selectedBase,
  selectedFont,
  selectedSize,
  selectedColour,
  baseMaterial,
  baseColour,
  baseShape
}) => {
  const fontStyle = {
    fontFamily: `${selectedFont}`
  };

  if (previewText === '') {
    previewText = 'miejsce na neon';
  }

  //check if the label of material is true, if so take its value in that object, eg. label: "biały" -> value: "white"
  const baseColourValue = data[baseMaterial].find(
    search => search.label === baseColour
  ).value;

  const neonColour = selectedColour.neonValue;
  const sizeClass = selectedSize === 'small' ? 'small' : 'big';

  let defaultClasses = classnames(
    styles.text,
    styles[neonColour],
    styles[sizeClass]
  );

  let withBaseClasses = classnames(
    defaultClasses,
    styles[baseShape],
    styles[baseMaterial],
    styles[`${baseMaterial}_${baseColourValue}`]
  );
  let previewClasses = classnames(styles.container, !lightOn && styles.noLight);

  const neonClasses =
    selectedBase === 'noBase' ? defaultClasses : withBaseClasses;

  const preview = previewText.split('\n').map((item, key) => {
    return (
      <span key={key}>
        {item}
        <br />
      </span>
    );
  });

  return (
    <div className={previewClasses}>
      <div id="neonPreview" className={neonClasses} style={fontStyle}>
        <p id="neon" style={fontStyle}>
          {preview}
        </p>
      </div>
    </div>
  );
};

Preview.propTypes = {
  data: PropTypes.object,
  selectedColour: PropTypes.object,
  lightOn: PropTypes.bool,
  previewText: PropTypes.string,
  selectedBase: PropTypes.string,
  selectedFont: PropTypes.string,
  selectedSize: PropTypes.string,
  baseMaterial: PropTypes.string,
  baseColour: PropTypes.string,
  baseShape: PropTypes.string
};

export default Preview;
