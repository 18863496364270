const sidebarData = {
  font: {
    fonts: [
      {
        fontLabel: 'Warszawa',
        fontFamily: 'Quicksand'
      },
      {
        fontLabel: 'Barcelona',
        fontFamily: 'Amatic'
      },
      {
        fontLabel: 'New York',
        fontFamily: 'cmu'
      },
      {
        fontLabel: 'Havana',
        fontFamily: 'Havana'
      },
      {
        fontLabel: 'Porto',
        fontFamily: 'Pecita'
      },
      {
        fontLabel: 'Amsterdam',
        fontFamily: 'Shadows'
      },
      {
        fontLabel: 'Tokyo',
        fontFamily: 'Tamoro'
      },
      {
        fontLabel: 'Florencja',
        fontFamily: 'LearningCurve'
      }
    ]
  },
  size: {
    sizes: [
      {
        value: 10,
        label: 'small'
      },
      {
        value: 15,
        label: 'big'
      }
    ]
  },
  colour: {
    colours: [
      {
        label: 'różowy',
        value: 'superPink',
        pipeLabel: 'biały',
        pipeValue: 'white'
      },
      {
        label: 'fioletowy',
        value: 'superPurple',
        pipeLabel: 'biały',
        pipeValue: 'white'
      },
      {
        label: 'niebieski',
        value: 'superBlue',
        pipeLabel: 'biały',
        pipeValue: 'white'
      },
      {
        label: 'zielony',
        value: 'superGreen',
        pipeLabel: 'biały',
        pipeValue: 'white'
      },
      {
        label: 'turkusowy',
        value: 'turqouise',
        pipeLabel: 'biały lub o lekko zielonkawym odcieniu',
        pipeValue: 'white'
      },
      {
        label: 'klasyczny czerwony',
        value: 'classicRed',
        pipeLabel: 'przezroczysty',
        pipeValue: 'transparent'
      },
      {
        label: 'klasyczny  niebieski',
        value: 'classicBlue',
        pipeLabel: 'przezroczysty',
        pipeValue: 'transparent'
      },
      {
        label: 'ciepły biały',
        value: 'warmWhite',
        pipeLabel: 'biały',
        pipeValue: 'white'
      },
      {
        label: 'zimny biały',
        value: 'coldWhite',
        pipeLabel: 'biały',
        pipeValue: 'white'
      },
      {
        label: 'czerwony-rubinowy',
        value: 'rubyRed',
        pipeLabel: 'czerwony',
        pipeValue: 'red'
      },
      {
        label: 'pomarańczowy',
        value: 'superOrange',
        pipeLabel: 'pomarańczowy',
        pipeValue: 'orange'
      },
      {
        label: 'złoty żółty',
        value: 'novialGold',
        pipeLabel: 'żółty',
        pipeValue: 'yellow'
      },
      {
        label: 'kobaltowy niebieski',
        value: 'cobaltBlue',
        pipeLabel: 'niebieski',
        pipeValue: 'blue'
      },
      {
        label: 'szmaragdowy zielony',
        value: 'emeraldGreen',
        pipeLabel: 'zielony',
        pipeValue: 'green'
      }
    ]
  },
  cable: {
    colours: [
      {
        label: 'czarny',
        value: 'black'
      },
      {
        label: 'biały',
        value: 'white'
      }
    ]
  },
  base: {
    options: [
      {
        label: 'brak podkładu',
        value: 'noBase'
      },
      {
        label: 'z podkładem',
        value: 'addBase'
      }
    ],
    materials: [
      {
        label: 'akryl',
        value: 'acrylic'
      },
      {
        label: 'aluminium',
        value: 'aluminium'
      },
      {
        label: 'drewno',
        value: 'wood'
      }
    ],
    shape: [
      {
        label: 'prostokąt',
        value: 'rectangle'
      },
      {
        label: 'chmurka',
        value: 'cloud'
      }
    ],
    colours: {
      acrylic: [
        {
          label: 'czarny',
          value: 'black'
        },
        {
          label: 'biały',
          value: 'white'
        },
        {
          label: 'transparentny',
          value: 'transparent'
        }
      ],
      aluminium: [
        {
          label: 'czarny (9005)',
          value: 'alBlack'
        },
        {
          label: 'biały (9016)',
          value: 'alWhite'
        },
        {
          label: 'surowy (9006)',
          value: 'alRaw'
        },
        {
          label: 'aluminium (9007)',
          value: 'alPure'
        },
        {
          label: 'żółty (1023)',
          value: 'alYellow'
        },
        {
          label: 'czerwony (3020)',
          value: 'alRed'
        },
        {
          label: 'niebieski (5015)',
          value: 'alBlue'
        },
        {
          label: 'zielony (6018)',
          value: 'alGreen'
        },
        {
          label: 'różowy (4010)',
          value: 'alPink'
        },
        {
          label: 'szary (7016)',
          value: 'alGray'
        }
      ],
      wood: [
        {
          label: 'surowa sosna',
          value: 'pine'
        }
      ]
    },
    handle: [
      {
        label: 'uchwyt z zasilaczem',
        value: 'handleWithPower'
      },
      {
        label: 'otwory w rogach',
        value: 'handleWithHoles'
      }
    ]
  },
  delivery: [
    { label: 'kurier', value: 'courier' },
    {
      label: 'odbiór osobisty',
      value: 'personally'
    }
  ]
};
export default sidebarData;
