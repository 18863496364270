let learningCurve = {
  smallPrice: 80,
  bigPrice: 94,
  0: {
    id: 70,
    small: "6.3",
    big: "9",
  },
  1: {
    id: 71,
    small: "4.5",
    big: "6.5",
  },
  2: {
    id: 72,
    small: "7.3",
    big: "10.5",
  },
  3: {
    id: 73,
    small: "7.3",
    big: "10.5",
  },
  4: {
    id: 74,
    small: "7.3",
    big: "10.5",
  },
  5: {
    id: 75,
    small: "7.3",
    big: "10.5",
  },
  6: {
    id: 76,
    small: "6.3",
    big: "9",
  },
  7: {
    id: 77,
    small: "6.3",
    big: "9",
  },
  8: {
    id: 78,
    small: "7.3",
    big: "10.5",
  },
  9: {
    id: 79,
    small: "6.3",
    big: "9",
  },
  a: {
    id: 0,
    small: "7.3",
    big: "10.5",
  },
  A: {
    id: 1,
    small: "9.8",
    big: "14.2",
  },
  ą: {
    id: 2,
    small: "7.3",
    big: "10.5",
  },
  Ą: {
    id: 3,
    small: "9.8",
    big: "14.2",
  },
  b: {
    id: 4,
    small: "6.3",
    big: "9",
  },
  B: {
    id: 5,
    small: "9",
    big: "13",
  },
  c: {
    id: 6,
    small: "5.3",
    big: "7.3",
  },
  C: {
    id: 7,
    small: "7.3",
    big: "10.5",
  },
  ć: {
    id: 8,
    small: "5.3",
    big: "7.3",
  },
  Ć: {
    id: 9,
    small: "7.3",
    big: "10.5",
  },
  d: {
    id: 10,
    small: "7.3",
    big: "10.5",
  },
  D: {
    id: 11,
    small: "9.8",
    big: "14.2",
  },
  e: {
    id: 12,
    small: "5.3",
    big: "7.3",
  },
  E: {
    id: 13,
    small: "8.5",
    big: "12.3",
  },
  ę: {
    id: 14,
    small: "5.3",
    big: "7.3",
  },
  Ę: {
    id: 15,
    small: "8.5",
    big: "12.3",
  },
  f: {
    id: 16,
    small: "7.3",
    big: "10.5",
  },
  F: {
    id: 17,
    small: "10.9",
    big: "16",
  },
  g: {
    id: 18,
    small: "7.3",
    big: "10.5",
  },
  G: {
    id: 19,
    small: "13.7",
    big: "21",
  },
  h: {
    id: 20,
    small: "7.3",
    big: "10.5",
  },
  H: {
    id: 21,
    small: "9.8",
    big: "14.2",
  },
  i: {
    id: 22,
    small: "4.5",
    big: "6.5",
  },
  I: {
    id: 23,
    small: "8.5",
    big: "12.3",
  },
  j: {
    id: 24,
    small: "7.3",
    big: "10.5",
  },
  J: {
    id: 25,
    small: "8.5",
    big: "12.3",
  },
  k: {
    id: 26,
    small: "6.3",
    big: "9",
  },
  K: {
    id: 27,
    small: "9.8",
    big: "14.2",
  },
  l: {
    id: 28,
    small: "5.3",
    big: "7.3",
  },
  L: {
    id: 29,
    small: "10.9",
    big: "16",
  },
  ł: {
    id: 30,
    small: "5.3",
    big: "7.3",
  },
  Ł: {
    id: 31,
    small: "10.9",
    big: "16",
  },
  m: {
    id: 32,
    small: "10.9",
    big: "16",
  },
  M: {
    id: 33,
    small: "13.7",
    big: "21",
  },
  n: {
    id: 34,
    small: "8.5",
    big: "12.3",
  },
  N: {
    id: 35,
    small: "9",
    big: "13",
  },
  ń: {
    id: 36,
    small: "8.5",
    big: "12.3",
  },
  Ń: {
    id: 37,
    small: "9",
    big: "13",
  },
  o: {
    id: 38,
    small: "6.3",
    big: "9",
  },
  O: {
    id: 39,
    small: "9",
    big: "13",
  },
  ó: {
    id: 40,
    small: "6.3",
    big: "9",
  },
  Ó: {
    id: 41,
    small: "9",
    big: "13",
  },
  p: {
    id: 42,
    small: "8.5",
    big: "12.3",
  },
  P: {
    id: 43,
    small: "8.5",
    big: "12.3",
  },
  q: {
    id: 44,
    small: "6.3",
    big: "9",
  },
  Q: {
    id: 45,
    small: "8.5",
    big: "12.3",
  },
  r: {
    id: 46,
    small: "5.3",
    big: "7.3",
  },
  R: {
    id: 47,
    small: "9",
    big: "13",
  },
  s: {
    id: 48,
    small: "6.3",
    big: "9",
  },
  S: {
    id: 49,
    small: "9",
    big: "13",
  },
  ś: {
    id: 50,
    small: "6.3",
    big: "9",
  },
  Ś: {
    id: 51,
    small: "9",
    big: "13",
  },
  t: {
    id: 52,
    small: "4.5",
    big: "6.5",
  },
  T: {
    id: 53,
    small: "9",
    big: "13",
  },
  u: {
    id: 54,
    small: "6.3",
    big: "9",
  },
  U: {
    id: 55,
    small: "9",
    big: "13",
  },
  v: {
    id: 56,
    small: "7.3",
    big: "10.5",
  },
  V: {
    id: 57,
    small: "9",
    big: "13",
  },
  w: {
    id: 58,
    small: "9",
    big: "13",
  },
  W: {
    id: 59,
    small: "13.7",
    big: "21",
  },
  x: {
    id: 60,
    small: "7.3",
    big: "10.5",
  },
  X: {
    id: 61,
    small: "9.8",
    big: "14.2",
  },
  y: {
    id: 62,
    small: "8.5",
    big: "12.3",
  },
  Y: {
    id: 63,
    small: "9.8",
    big: "14.2",
  },
  z: {
    id: 64,
    small: "7.3",
    big: "10.5",
  },
  Z: {
    id: 65,
    small: "8.5",
    big: "12.3",
  },
  ż: {
    id: 66,
    small: "7.3",
    big: "10.5",
  },
  Ż: {
    id: 67,
    small: "8.5",
    big: "12.3",
  },
  ź: {
    id: 68,
    small: "7.3",
    big: "10.5",
  },
  Ź: {
    id: 69,
    small: "8.5",
    big: "12.3",
  },
  "!": {
    id: 80,
    small: "4.5",
    big: "6.5",
  },
  "@": {
    id: 81,
    small: "10.9",
    big: "16",
  },
  "#": {
    id: 82,
    small: "8.5",
    big: "12.3",
  },
  $: {
    id: 83,
    small: "5.3",
    big: "7.3",
  },
  "&": {
    id: 84,
    small: "8.5",
    big: "12.3",
  },
  "(": {
    id: 85,
    small: "7.3",
    big: "10.5",
  },
  ")": {
    id: 86,
    small: "7.3",
    big: "10.5",
  },
  "-": {
    id: 87,
    small: "5.3",
    big: "7.3",
  },
  "+": {
    id: 88,
    small: "6.3",
    big: "9",
  },
  ",": {
    id: 89,
    small: "2",
    big: "3",
  },
  ".": {
    id: 90,
    small: "2",
    big: "3",
  },
  "<": {
    id: 91,
    small: "4.5",
    big: "6.5",
  },
  ">": {
    id: 92,
    small: "4.5",
    big: "6.5",
  },
  "?": {
    id: 93,
    small: "6.3",
    big: "9",
  },
  "/": {
    id: 94,
    small: "5.3",
    big: "7.3",
  },
  space: {
    id: 95,
    small: "4.5",
    big: "7",
  },
};

export default learningCurve;
