import React from 'react';
import { I18n } from '../../Translations/I18n';
import ResponseHeader from './ResponseHeader/ResponseHeader';
import ResponseContent from './ResponseContent/ResponseContent';
import ButtonConfirmation from '../../ButtonConfirmation/ButtonConfirmation';
import styles from './ResponseMessage.module.scss';

const successMessage = (
  <I18n>
    {ctx => (
      <React.Fragment>
        <ResponseHeader context="success" />
        <div className={styles.content}>
          <div className={styles.contentBigger}>
            <p>{ctx('response.congratulation')}</p>
          </div>
          <ResponseContent context="success" />
        </div>
      </React.Fragment>
    )}
  </I18n>
);

const errorMessage = (
  <React.Fragment>
    <ResponseHeader context="error" />
    <div className={styles.content}>
      <ResponseContent context="error" />
    </div>
  </React.Fragment>
);

const ResponseMessage = ({
  serverErrorResponse,
  resetStates,
  handleToggleChange
}) => (
  <I18n>
    {ctx => (
      <div className={styles.container}>
        {serverErrorResponse === false ? successMessage : errorMessage}
        <ButtonConfirmation
          input={' '}
          content={ctx('response.button')}
          name="resetStates"
          value={resetStates}
          handleToggleChange={handleToggleChange}
        />
      </div>
    )}
  </I18n>
);

export default ResponseMessage;
