import React from 'react';
import styles from './RadioButtonGroup.module.scss';
import PropTypes from 'prop-types';

const RadioButtonGroup = ({ data, name, state, method }) => {
  const radioOption = data.map((item, i) => {
    return (
      <li key={`${name}-${i}`} className="RadioButtonGroup__item">
        <input
          className={styles.input}
          type="radio"
          name={name}
          id={item.value}
          value={item.value}
          defaultChecked={item.value === state}
        />

        <label htmlFor={item.value} className="RadioButtonGroup__label">
          {item.label}
        </label>
      </li>
    );
  });

  return (
    <ul className={styles.list} onChange={e => method(e.target)}>
      {radioOption}
    </ul>
  );
};

RadioButtonGroup.propTypes = {
  data: PropTypes.array,
  name: PropTypes.string,
  state: PropTypes.string,
  method: PropTypes.func
};

export default RadioButtonGroup;
