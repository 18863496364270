import React from 'react';
import './Checkbox.module.scss';
import PropTypes from 'prop-types';

const Checkbox = ({ id, name, state, content, children, method }) => {
  return (
    <div className="Checkbox__wrapper">
      <input
        type="checkbox"
        id={id}
        name={name}
        value={state}
        onClick={e => {
          method(e.currentTarget);
        }}
      />
      <label htmlFor={id}>{content || children}</label>
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  state: PropTypes.bool,
  content: PropTypes.string,
  method: PropTypes.func
};

export default Checkbox;
