import React from 'react';
import { I18n } from '../Translations/I18n';
import logo from '../../assets/images/logo.svg';
import styles from './Header.module.scss';

const Header = () => {
  return (
    <I18n>
      {ctx => (
        <header className={styles.container}>
          <h1 className={styles.heading}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={ctx('header.link')}
              className={styles.logo}
              title={ctx('header.title')}
            >
              <img
                className={styles.logoImage}
                src={logo}
                alt={ctx('header.alt')}
              />
            </a>
          </h1>
        </header>
      )}
    </I18n>
  );
};

export default Header;
