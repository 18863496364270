import React from 'react';
import styles from './CheckboxError.module.scss';
import PropTypes from 'prop-types';

const CheckboxError = ({ state, status }) => (
  <React.Fragment>
    {!state && status && <div className={styles.checkboxError}>{status}</div>}
  </React.Fragment>
);

CheckboxError.propTypes = {
  state: PropTypes.bool,
  status: PropTypes.string
};

export default CheckboxError;
