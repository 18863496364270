import useWindowDimensions from '../windowDimentions.js';

const adjustBackgroundHeight = () => {
  const { height, width } = useWindowDimensions();
  let style = { height: '100%' };
  if (width > 1240 && height >= 755) {
    style = { height: '100vh' };
  }
  return style;
};

export default adjustBackgroundHeight;
