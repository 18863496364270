import React from 'react';
import { I18n } from '../../Translations/I18n';
import InfoBox from '../../InfoBox/InfoBox';
import styles from './InputText.module.scss';
import PropTypes from 'prop-types';

const InputText = ({ input, handleInputChange }) => {
  return (
    <I18n>
      {ctx => (
        <div className="Sidebar__content">
          <div className="Sidebar__wrapper">
            <h2 className="Sidebar__heading">{ctx('input.heading')}</h2>
            <textarea
              className={styles.input}
              name="textarea"
              rows="3"
              maxLength="120"
              value={input}
              placeholder={ctx('input.placeholder')}
              onChange={e => {
                handleInputChange(e.target.value);
              }}
            />
            <p className={styles.description}>{ctx('input.desc')}</p>
          </div>
          <InfoBox ctx={ctx('input.note')} state={false} />
        </div>
      )}
    </I18n>
  );
};

InputText.propTypes = {
  input: PropTypes.string,
  handleInputChange: PropTypes.func
};

export default InputText;
