import React from 'react';
import { I18n } from '../../../Translations/I18n';
import InfoBox from '../../../InfoBox/InfoBox';
import ButtonColour from '../../../ButtonColour/ButtonColour';
import styles from './BaseColour.module.scss';
import PropTypes from 'prop-types';

const BaseColour = ({ data, material, state, method }) => {
  const colourContent = data[material].map(colour => {
    const isCurrent = colour.label === state;
    return (
      <li key={colour.value} className={styles.item}>
        <ButtonColour
          component={material}
          colour={colour}
          isCurrent={isCurrent}
          method={method}
        />
      </li>
    );
  });

  return (
    <I18n>
      {ctx => (
        <React.Fragment>
          <ul className={styles.list}>{colourContent}</ul>
          <InfoBox ctx={ctx('base.note_colour')} state={state} />
        </React.Fragment>
      )}
    </I18n>
  );
};

BaseColour.propTypes = {
  data: PropTypes.object,
  material: PropTypes.string,
  state: PropTypes.string,
  method: PropTypes.func
};

export default BaseColour;
