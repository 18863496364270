import React from 'react';
import Icon from '../Icon/Icon';
import styles from './LightSwitcher.module.scss';
import PropTypes from 'prop-types';

const LightSwitcher = ({ lightOn, handleToggleChange }) => {
  const buttonDesc = lightOn === true ? 'Włącz neon' : 'Wyłącz neon';

  return (
    <button
      className={styles.container}
      name="lightOn"
      value={lightOn}
      onClick={e => {
        handleToggleChange(e.currentTarget);
      }}
    >
      <Icon src={'light'} />
      <span className={styles.description}>{buttonDesc}</span>
    </button>
  );
};

LightSwitcher.propTypes = {
  lightOn: PropTypes.bool,
  handleToggleChange: PropTypes.func
};

export default LightSwitcher;
