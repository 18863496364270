import React from 'react';
import './Link.module.scss';
import PropTypes from 'prop-types';

const Link = ({ text, link }) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={link || '#'}
    className="Link__anchor"
  >
    {text}
  </a>
);

Link.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string
};

export default Link;
