import React from 'react';
import { I18n } from '../../../Translations/I18n';
import Link from '../../../Link/Link';
import styles from './ResponseContent.module.scss';

const ResponseContent = ({ context }) => (
  <I18n>
    {ctx => (
      <div className={styles.contentDefault}>
        {context === 'success' ? <p>{ctx(`response.thanks`)}</p> : null}
        <span>{ctx(`response.${context}Contact`)}</span>
        <div>
          <span>{ctx('response.email')}</span>
          <Link text={ctx('response.contactEmail')} />
          <p>{ctx('response.contactPhone')}</p>
        </div>
        <span>{ctx('response.greetings')}</span>
        <Link link="http://arneon.com/" text={ctx('response.greetingsLink')} />
      </div>
    )}
  </I18n>
);

export default ResponseContent;
