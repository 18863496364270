import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import adjustButtonPosition from '../adjustButtonPosition.js';
import FieldHeader from '../FieldHeader/FieldHeader';
import FieldWrapper from '../FieldWrapper/FieldWrapper';
import FieldCheckbox from '../FieldCheckbox/FieldCheckbox';
import FormSchema from '../formValidation';
import { I18n } from '../../../Translations/I18n';
import Icon from '../../../Icon/Icon.js';
import classnames from 'classnames';
import styles from './ContactForm.module.scss';
const axios = require('../../../../axios-configuration');

const ContactForm = ({
  translateProps,
  states,
  confirmationForm,
  handleCheckboxFormChange,
  handleToggleChange,
  handleCheckboxStatus
}) => {
  const {
    input,
    font,
    size,
    selectedColour,
    cableColour,
    baseValue,
    neonPrice,
    deliveryPrice
  } = translateProps();
  const {
    additionalAddress,
    rulesAgreement,
    policyAgreement,
    saleAgreement
  } = states;

  const freeDelivery = 0;
  const newDeliveryPrice =
    states.delivery === 'courier' ? deliveryPrice : freeDelivery;

  const initialValues = {
    name: '',
    email: '',
    phone: '',
    street: '',
    streetNumber: '',
    postalCode: '',
    city: '',
    company: '',
    nip: '',
    paymentStreet: '',
    paymentStreetNumber: '',
    paymentPostalCode: '',
    paymentCity: '',
    additionalAddress: false,
    neonSummary: [
      {
        text: input,
        font,
        size,
        colour: selectedColour,
        cableColour,
        baseValue,
        neonPrice
      }
    ]
  };

  const { buttonStyle } = adjustButtonPosition(baseValue, additionalAddress);
  const [loading, setLoading] = useState(false);

  return (
    <Formik
      confirmationForm={confirmationForm}
      enableReinitialize={true} //has to be true to reinitialize initialValues...
      initialValues={initialValues}
      validationSchema={FormSchema}
      deliveryPrice={newDeliveryPrice}
      onSubmit={(values, actions) => {
        if (!rulesAgreement || !policyAgreement || !saleAgreement) {
          actions.setSubmitting(false);
          actions.setStatus(
            handleCheckboxStatus({
              rules: 'To pole jest wymagane',
              policy: 'To pole jest wymagane',
              sale: 'To pole jest wymagane'
            })
          );
          return;
        }

        actions.setSubmitting(true);
        setLoading(!loading);
        axios
          .post('/form/mailing', {
            newDeliveryPrice,
            ...values
          })
          .then(response => {
            setLoading(loading);
            handleToggleChange('confirmationForm');
            actions.setSubmitting(false);
          })
          .catch(error => {
            // console.log('Error: ', error.response.status, error.response.data.message);
            console.error(error);
            setLoading(loading);
            handleToggleChange('serverErrorResponse');
            actions.setSubmitting(false);
          });
      }}
    >
      {({ values, touched, errors, status, isValid, isSubmitting }) => {
        const canSubmit =
          isValid && rulesAgreement && policyAgreement && saleAgreement;
        return (
          <I18n>
            {ctx => (
              <Form className={styles.container}>
                <fieldset
                  className={classnames(
                    styles.defaultField,
                    styles.contactWrapper
                  )}
                >
                  <FieldHeader heading={'headingContact'} />
                  <FieldWrapper
                    error={touched.name && errors.name}
                    field={'name'}
                  />
                  <FieldWrapper
                    error={touched.phone && errors.phone}
                    field={'phone'}
                    type={'tel'}
                  />
                  <FieldWrapper
                    error={touched.email && errors.email}
                    field={'email'}
                    type={'email'}
                  />
                </fieldset>
                <fieldset
                  className={classnames(
                    styles.defaultField,
                    styles.addressWrapper
                  )}
                >
                  <FieldHeader heading={'headingAddress'} />
                  <FieldWrapper
                    error={touched.street && errors.street}
                    field={'street'}
                  />
                  <FieldWrapper
                    error={touched.streetNumber && errors.streetNumber}
                    field={'streetNumber'}
                  />
                  <FieldWrapper
                    error={touched.postalCode && errors.postalCode}
                    field={'postalCode'}
                  />
                  <FieldWrapper
                    error={touched.city && errors.city}
                    field={'city'}
                  />
                  <FieldCheckbox
                    name="additionalAddress"
                    values={values}
                    handleCheckboxFormChange={handleCheckboxFormChange}
                  />
                </fieldset>
                {values.additionalAddress ? (
                  <fieldset
                    className={classnames(
                      styles.defaultField,
                      styles.contactWrapper
                    )}
                  >
                    <FieldHeader heading={'headingPayment'} />
                    <FieldWrapper
                      error={touched.company && errors.company}
                      field={'company'}
                    />
                    <FieldWrapper
                      error={touched.paymentStreet && errors.paymentStreet}
                      field={'nip'}
                    />
                    <FieldWrapper
                      error={touched.paymentStreet && errors.paymentStreet}
                      field={'paymentStreet'}
                    />
                    <FieldWrapper
                      error={
                        touched.paymentStreetNumber &&
                        errors.paymentStreetNumber
                      }
                      field={'paymentStreetNumber'}
                    />
                    <FieldWrapper
                      error={
                        touched.paymentPostalCode && errors.paymentPostalCode
                      }
                      field={'paymentPostalCode'}
                    />
                    <FieldWrapper
                      error={touched.paymentCity && errors.paymentCity}
                      field={'paymentCity'}
                    />
                  </fieldset>
                ) : null}
                <Field type="hidden" name={values.neonSummary} />
                <button
                  type="submit"
                  title={
                    !canSubmit ? 'Uzupełnij wymagane pola w formularzu' : ''
                  }
                  style={buttonStyle}
                  className={classnames(
                    'Formik__submitButton',
                    !canSubmit && styles.disabled
                  )}
                  disabled={!canSubmit && isSubmitting}
                >
                  {ctx('formSummary.finalization')}
                  {loading ? <Icon src={'loader'} /> : null}
                </button>
              </Form>
            )}
          </I18n>
        );
      }}
    </Formik>
  );
};

export default ContactForm;
