import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import styles from '../Background.module.scss';
import livingroom from '../../../assets/images/bg_livingroom.jpg';
import bedroom from '../../../assets/images/bg_bedroom.jpg';
import kidsroom from '../../../assets/images/bg_kids-room.jpg';

const imageSrc = {
  livingroom,
  bedroom,
  kidsroom
};

const BackgroundImageCarousel = ({ src = livingroom }) => {
  return (
    <CSSTransitionGroup
      transitionName="backgroundImage"
      transitionEnterTimeout={500}
      transitionLeaveTimeout={500}
    >
      <div
        key={src}
        className={styles.backgroundImage}
        style={{ backgroundImage: `url(${imageSrc[src]})` }}
      />
    </CSSTransitionGroup>
  );
};

export default BackgroundImageCarousel;
