import React from 'react';
import InfoBox from '../../../InfoBox/InfoBox';
import RadioButtonGroup from '../../../RadioButtonGroup/RadioButtonGroup';
import PropTypes from 'prop-types';

const BaseShape = ({ data, state, method }) => {
  return (
    <React.Fragment>
      <RadioButtonGroup
        data={data}
        name="baseShape"
        state={state}
        method={method}
      />
      <InfoBox ctx={false} state={state} direction={'column'} />
    </React.Fragment>
  );
};

BaseShape.propTypes = {
  data: PropTypes.array,
  state: PropTypes.string,
  method: PropTypes.func
};

export default BaseShape;
