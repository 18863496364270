import React from 'react';
import ButtonConfirmation from '../../ButtonConfirmation/ButtonConfirmation';
import { I18n } from '../../Translations/I18n';
import classnames from 'classnames';
import styles from './OrderSummary.module.scss';
import PropTypes from 'prop-types';

const OrderSummary = ({
  input,
  neonOrder,
  selectedBase,
  visibleForm,
  handleToggleChange
}) => {
  const { neonWidth, neonHeight, neonPrice, delivery } = neonOrder;
  const overSize =
    selectedBase === 'addBase' && (neonWidth > 120 || neonHeight > 50);

  let summaryContainerClasses = classnames(
    styles.container,
    overSize && styles.alert
  );

  let summarySizeClasses = classnames(
    styles.textSize,
    overSize && styles.alert
  );

  const sizeText = (
    <I18n>
      {ctx => (
        <p className={summarySizeClasses}>
          {ctx('summary.size')}
          <span>
            {ctx('summary.height')} {neonHeight} {ctx('summary.unit')} x{' '}
            {ctx('summary.width')} {neonWidth} {ctx('summary.unit')}
          </span>
        </p>
      )}
    </I18n>
  );

  const priceText = (
    <I18n>
      {ctx => (
        <React.Fragment>
          <div className={styles.textPrice}>
            <span className={(styles.textPrice, styles.highligted)}>
              {ctx('summary.price')}
            </span>
            <div>
              <span className={(styles.textPrice, styles.highligted)}>
                {neonPrice}
                {ctx('summary.currency')}
              </span>{' '}
              {ctx('summary.delivery')} {delivery} {ctx('summary.currency')}
            </div>
          </div>
          <ButtonConfirmation
            input={input}
            content={ctx('summary.button')}
            name="visibleForm"
            value={visibleForm}
            handleToggleChange={handleToggleChange}
          />
        </React.Fragment>
      )}
    </I18n>
  );

  const alertText = (
    <I18n>
      {ctx => (
        <div className={styles.textAlert}>
          <p>{ctx('summary.alert')}</p>
        </div>
      )}
    </I18n>
  );

  return (
    <div className={summaryContainerClasses}>
      <div className={styles.content}>
        {sizeText}
        {overSize ? alertText : priceText}
      </div>
    </div>
  );
};

OrderSummary.propTypes = {
  visibleForm: PropTypes.bool,
  neonOrder: PropTypes.objectOf(PropTypes.number),
  selectedBase: PropTypes.string,
  handleToggleChange: PropTypes.func
};

export default OrderSummary;
