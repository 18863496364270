import React from 'react';
import styles from './ButtonColour.module.scss';
import classnames from 'classnames';
import variables from '../../scss/_variables.scss';
import PropTypes from 'prop-types';

const ButtonColour = ({
  component = 'default',
  name,
  colour,
  isCurrent,
  method
}) => {
  const className = classnames(
    styles[component],
    colour.value,
    isCurrent && styles.selected
  );

  const backgroundStyle = {
    backgroundColor: variables[colour.value]
  };

  return (
    <button
      className={className}
      style={backgroundStyle}
      type="button"
      name={name || component}
      value={colour.value}
      data-label={colour.label}
      data-pipe={colour.pipeLabel || false}
      onClick={e => method(e.target)}
    >
      {' '}
    </button>
  );
};

ButtonColour.propTypes = {
  isCurrent: PropTypes.bool,
  component: PropTypes.string,
  name: PropTypes.string,
  colour: PropTypes.object,
  method: PropTypes.func
};

export default ButtonColour;
