import * as Yup from 'yup';

const wordRegex = /^[a-zA-Zęóąśłżźćń,.'-\s]+$/i;
const charsRegex = /(\w+)\s*(\w+)/;
const postalCodeRegex = /[0-9]{2}-?\s?[0-9]{3}/;
const phoneRegex = /^([0-9()/+-]*)$/;

let FormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Proszę wprowadzić nie mniej niż 2 znaki')
    .matches(wordRegex, 'Niedozwolone znaki')
    .max(60, 'Proszę wprowadzić nie więcej niż 60 znaków')
    .required('To pole jest wymagane'),
  email: Yup.string()
    .email('Niepoprawny email')
    .required('To pole jest wymagane'),
  phone: Yup.string()
    .min(2, 'Proszę wprowadzić nie mniej niż 2 znaki')
    .matches(phoneRegex, 'Niedozwolone znaki')
    .max(20, 'Proszę wprowadzić nie więcej niż 20 znaków')
    .required('To pole jest wymagane'),
  street: Yup.string()
    .min(2, 'Proszę wprowadzić nie mniej niż 2 znaki')
    .matches(charsRegex, 'Niedozwolone znaki')
    .max(60, 'Proszę wprowadzić nie więcej niż 60 znaków')
    .required('To pole jest wymagane'),
  streetNumber: Yup.string().required('To pole jest wymagane'),
  postalCode: Yup.string()
    .matches(postalCodeRegex, 'Niepoprawny format (00-000)')
    .max(6, 'Proszę wprowadzić nie więcej niż 6 znaków')
    .required('To pole jest wymagane'),
  city: Yup.string()
    .min(2, 'Proszę wprowadzić nie mniej niż 2 znaków')
    .matches(wordRegex, 'Niedozwolone znaki')

    .required('To pole jest wymagane'),
  company: Yup.string(),
  nip: Yup.string(),
  paymentStreet: Yup.string(),
  paymentStreetNumber: Yup.string(),
  paymentPostalCode: Yup.string(),
  paymentCity: Yup.string()
});

export default FormSchema;
