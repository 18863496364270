import React from 'react';
import { I18n } from '../../../Translations/I18n';
import styles from './PaymentInfo.module.scss';

const PaymentInfo = () => (
  <I18n>
    {ctx => (
      <div className={styles.paymentWrapper}>
        <p className={styles.infoText}>{ctx('formSummary.paymentText')}</p>
        <div className={styles.paymentDetails}>
          <p className={styles.paymentParagraph}>{ctx('formSummary.bank')}</p>
          <p className={styles.paymentParagraphBold}>{ctx('formSummary.account')}</p>
          <p className={styles.paymentParagraph}>{ctx('formSummary.companyName')}</p>
        </div>
      </div>
    )}
  </I18n>
);

export default PaymentInfo;
