import React from 'react';
import { I18n } from '../../Translations/I18n';

const getSummaryContent = (
  input,
  font,
  size,
  selectedColour,
  cableColour,
  baseValues
) => {
  return (
    <I18n>
      {ctx => (
        <React.Fragment>
          <div className="infobox__content--row">
            <p className="infobox__text--default">{ctx('formSummary.text')}</p>
            <p className="infobox__text--highlighted">{input}</p>
          </div>
          <div className="infobox__content--row">
            <p className="infobox__text--default">{ctx('formSummary.font')}</p>
            <p className="infobox__text--highlighted">{font}</p>
          </div>
          <div className="infobox__content--row">
            <p className="infobox__text--default">{ctx('formSummary.size')}</p>
            <p className="infobox__text--highlighted">{`${size} cm`}</p>
          </div>
          <div className="infobox__content--row">
            <p className="infobox__text--default">{ctx('formSummary.neonColour')}</p>
            <p className="infobox__text--highlighted">{selectedColour}</p>
          </div>
          <div className="infobox__content--row">
            <p className="infobox__text--default">
              {ctx('formSummary.cableColour')}
            </p>
            <p className="infobox__text--highlighted">{cableColour}</p>
          </div>
          <div className="infobox__content--row">
            <p className="infobox__text--default">
              {ctx('formSummary.neonBase')}
            </p>
            <p className="infobox__text--highlighted">{baseValues}</p>
          </div>
        </React.Fragment>
      )}
    </I18n>
  );
};

export default getSummaryContent;
