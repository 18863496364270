import React from 'react';
import classnames from 'classnames';
import styles from './SidebarTabs.module.scss';
import PropTypes from 'prop-types';
import Icon from '../../Icon/Icon';

const SidebarTabs = ({ currentTab, handleTabChange }) => {
  const tabs = ['text', 'font', 'size', 'colour', 'cable', 'base'];
  const tabList = tabs.map(tab => {
    const isCurrent = tab === currentTab;
    let tabItemClasses = classnames(styles.item, isCurrent && styles.active);

    return (
      <li
        className={tabItemClasses}
        key={`tab-${tab}`}
        onClick={() => {
          handleTabChange(tab);
        }}
      >
        <Icon key={tab} src={tab} />
      </li>
    );
  });

  return (
    <nav className={styles.container}>
      <ul className={styles.list}>{tabList}</ul>
    </nav>
  );
};

SidebarTabs.propTypes = {
  currentTab: PropTypes.string,
  handleTabChange: PropTypes.func
};

const areEqual = (prevProps, nextProps) => {
  return prevProps.currentTab === nextProps.currentTab;
};

export default React.memo(SidebarTabs, areEqual);
