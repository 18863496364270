import React from 'react';
import { I18n } from '../../../Translations/I18n';
import RadioButtonGroup from '../../../RadioButtonGroup/RadioButtonGroup';
import styles from './PriceInfo.module.scss';

const PriceInfo = ({
  data,
  delivery,
  handleRadioButtonFormChange,
  neonPrice,
  deliveryPrice
}) => {
  const freeDelivery = 0;
  const newDeliveryPrice =
    delivery === 'courier' ? deliveryPrice : freeDelivery;
  let totalPrice = neonPrice + newDeliveryPrice;
  totalPrice = Number(totalPrice.toFixed(2));
  return (
    <I18n>
      {ctx => (
        <div className={styles.priceInfo}>
          <div className={styles.priceDetails}>
            <p className={styles.priceText}>{ctx('formSummary.price')}</p>
            <span className={styles.priceText}>
              {`${neonPrice} `}
              {ctx('summary.currency')}
            </span>
          </div>
          <div className={styles.priceWrapper}>
            <p className={styles.priceText}>{ctx('formSummary.delivery')}</p>
            <div className={styles.delivery}>
              <div className={styles.deliveryOptions}>
                <RadioButtonGroup
                  data={data}
                  name="delivery"
                  state={delivery}
                  method={handleRadioButtonFormChange}
                />
              </div>
              <div className={styles.deliveryPrices}>
                <span>
                  {`${deliveryPrice} `}
                  {ctx('summary.currency')}
                </span>
                <span>
                  {`${freeDelivery} `}
                  {ctx('summary.currency')}
                </span>
              </div>
            </div>
          </div>
          <p className={styles.deliveryNote}>
            {ctx('formSummary.deliveryAddress')}
          </p>
          <div className={styles.priceTotal}>
            <p>{ctx('formSummary.totalPrice')}</p>
            <span>
              {`${totalPrice} `}
              {ctx('summary.currency')}
            </span>
          </div>
        </div>
      )}
    </I18n>
  );
};

export default PriceInfo;
