import React from 'react';
import classnames from 'classnames';
import styles from './ButtonText.module.scss';
import PropTypes from 'prop-types';

const ButtonText = ({ component, name, state, text, isCurrent, method }) => {
  ButtonText.defaultProps = {
    component: 'default'
  };
  const className = classnames(styles[component], isCurrent && styles.selected);

  return (
    <button
      style={{ fontFamily: component === 'font' ? state : 'inherit' }}
      className={className}
      type="button"
      value={state}
      name={name}
      onClick={e => method(e.target)}
    >
      {text}
    </button>
  );
};

ButtonText.propTypes = {
  isCurrent: PropTypes.bool,
  component: PropTypes.string,
  name: PropTypes.string,
  state: PropTypes.string,
  text: PropTypes.string,
  method: PropTypes.func
};

export default ButtonText;
