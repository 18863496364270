import React from 'react';
import { I18n } from '../../Translations/I18n';
import ButtonColour from '../../ButtonColour/ButtonColour';
import styles from './CableColour.module.scss';
import PropTypes from 'prop-types';

const CableColour = ({ data, selectedCable, handleRadioButtonChange }) => {
  const cableContent = data.colours.map(colour => {
    const isCurrent = selectedCable === colour.value;
    return (
      <li key={`cable-${colour.value}`} className={styles.item}>
        <ButtonColour
          component={'cable'}
          name="selectedCable"
          colour={colour}
          isCurrent={isCurrent}
          method={handleRadioButtonChange}
        />
      </li>
    );
  });

  return (
    <I18n>
      {ctx => (
        <div className="Sidebar__content">
          <div className="Sidebar__wrapper">
            <h2 className="Sidebar__heading"> {ctx('cable.heading')} </h2>
            <ul className={styles.list}>{cableContent}</ul>
          </div>
        </div>
      )}
    </I18n>
  );
};

CableColour.propTypes = {
  data: PropTypes.object,
  selectedCable: PropTypes.string,
  handleRadioButtonChange: PropTypes.func
};

export default CableColour;
