import React from 'react';
import RadioButtonGroup from '../../../RadioButtonGroup/RadioButtonGroup';
import PropTypes from 'prop-types';

const BaseMaterial = ({ data, state, method }) => {
  return (
    <RadioButtonGroup
      data={data}
      name="baseMaterial"
      state={state}
      method={method}
    />
  );
};

BaseMaterial.propTypes = {
  data: PropTypes.array,
  state: PropTypes.string,
  method: PropTypes.func
};

export default BaseMaterial;
