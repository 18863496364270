import React from 'react';
import { I18n } from '../../Translations/I18n';
import ButtonText from '../../ButtonText/ButtonText';
import InfoBox from '../../InfoBox/InfoBox';
import styles from './TextSize.module.scss';
import PropTypes from 'prop-types';

const TextSize = ({ data, selectedSize, handleRadioButtonChange }) => {
  const sizeContent = data.sizes.map(size => {
    const isCurrent = size.label === selectedSize;
    return (
      <li key={size.label} className={styles.item}>
        <ButtonText
          component="size"
          name="selectedSize"
          state={size.label}
          text={`${size.value} cm`}
          isCurrent={isCurrent}
          method={handleRadioButtonChange}
        />
      </li>
    );
  });

  return (
    <I18n>
      {ctx => (
        <div className="Sidebar__content">
          <div className="Sidebar__wrapper">
            <h2 className="Sidebar__heading">{ctx('size.heading')}</h2>
            <ul className={styles.list}>{sizeContent}</ul>
          </div>
          <InfoBox
            ctx={ctx('size.image_desc')}
            state={'fontSize'}
            direction={'column'}
          />
        </div>
      )}
    </I18n>
  );
};

TextSize.propTypes = {
  data: PropTypes.object,
  selectedSize: PropTypes.string,
  handleColourChange: PropTypes.func
};

export default TextSize;
