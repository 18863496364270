import useWindowDimensions from '../../windowDimentions.js';

const adjustButtonPosition = (baseValue, additionalAddress) => {
  const { width } = useWindowDimensions();
  let buttonStyle = { bottom: '' };

  if (width > 1240) {
    if (baseValue.length < 20 && !additionalAddress) {
      buttonStyle = { bottom: '7%' };
    }

    if (baseValue.length < 20 && additionalAddress) {
      buttonStyle = { bottom: '5.5%' };
    }
  }
  return { buttonStyle };
};

export default adjustButtonPosition;
