import 'babel-polyfill';
import React, { PureComponent } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import ContactForm from './ContactForm/ContactForm';
import PaymentInfo from './PaymentInfo/PaymentInfo';
import PriceInfo from './PriceInfo/PriceInfo';
import { I18n } from '../../Translations/I18n';
import getSummaryContent from './getSummaryContent.js';
import InfoBox from '../../InfoBox/InfoBox';
import Checkbox from '../../Checkbox/Checkbox';
import CheckboxError from '../../CheckboxError/CheckboxError';
import Link from '../../Link/Link';
import classnames from 'classnames';
import styles from './Form.module.scss';

export default class Form extends PureComponent {
  state = {
    delivery: 'courier',
    rulesAgreement: false,
    policyAgreement: false,
    saleAgreement: false,
    statusRules: '',
    statusPolicy: '',
    statusSale: '',
    additionalAddress: false
  };

  handleCheckboxFormChange = target => {
    const name = target.name;
    this.setState({
      [name]: !this.state[name]
    });
  };

  handleCheckboxStatus = status => {
    this.setState({
      statusRules: status.rules,
      statusPolicy: status.policy,
      statusSale: status.sale
    });
  };

  handleRadioButtonFormChange = target => {
    const name = target.name;
    const value = target.value;
    this.setState({
      [name]: value
    });
  };

  translateProps = () => {
    const input = this.props.input;
    const hasBase = this.props.selectedBase;
    const selectedColour = this.props.selectedColour;
    const neonPrice = this.props.neonPrice;
    const deliveryPrice = this.props.deliveryPrice;

    const {
      baseMaterial,
      baseShape,
      baseHandle,
      baseColour
    } = this.props.baseOptions;

    const fontData = this.props.data.font;
    const sizeData = this.props.data.size;
    const cableData = this.props.data.cable;
    const baseData = this.props.data.base;

    const cableColour = cableData.colours.find(
      search => search.value === this.props.selectedCable
    ).label;

    const size = sizeData.sizes.find(
      search => search.label === this.props.selectedSize
    ).value;

    const font = fontData.fonts.find(
      search => search.fontFamily === this.props.selectedFont
    ).fontLabel;

    let baseValue = '';
    if (hasBase === 'noBase') {
      baseValue = baseData.options.find(
        search => search.value === this.props.selectedBase
      ).label;
    } else {
      const baseOptions = {
        materials: baseMaterial,
        shape: baseShape,
        handle: baseHandle
      };

      let baseLabels = Object.entries(baseOptions).map(([key, props]) => {
        const findBaseLabes = baseData[key].find(
          search => search.value === props
        ).label;
        return findBaseLabes;
      });

      baseLabels.splice(2, 0, baseColour);
      baseValue = baseLabels.join('/ ');
    }

    return {
      input,
      font,
      size,
      selectedColour,
      cableColour,
      baseValue,
      neonPrice,
      deliveryPrice
    };
  };

  render() {
    const {
      input,
      font,
      size,
      selectedColour,
      cableColour,
      baseValue,
      neonPrice,
      deliveryPrice
    } = this.translateProps();

    return (
      <I18n>
        {ctx => (
          <div
            className={classnames(
              styles.container,
              this.props.visibleForm && styles.open
            )}
          >
            <div className={styles.header}>
              <button
                className={styles.arrow}
                name="visibleForm"
                value={this.props.visibleForm}
                onClick={e => {
                  this.props.handleToggleChange(e.currentTarget);
                }}
              />
              <h2 className={styles.heading}>{ctx('form.headingForm')}</h2>
            </div>
            <PerfectScrollbar
              options={{ maxScrollbarLength: 90, suppressScrollX: true }}
            >
              <div
                className={classnames(
                  styles.form,
                  this.state.additionalAddress && styles.formIncrease
                )}
              >
                <ContactForm
                  translateProps={this.translateProps}
                  input={this.props.input}
                  states={this.state}
                  policyAgreement={this.state.policyAgreement}
                  confirmationForm={this.props.confirmationForm}
                  handleCheckboxFormChange={this.handleCheckboxFormChange}
                  handleToggleChange={this.props.handleToggleChange}
                  handleCheckboxStatus={this.handleCheckboxStatus}
                />
                <div className={styles.summaryContainer}>
                  <div className={styles.summaryContent}>
                    <h3 className={styles.summaryHeading}>
                      {ctx('formSummary.heading')}
                    </h3>
                    <InfoBox
                      content={getSummaryContent(
                        input,
                        font,
                        size,
                        selectedColour,
                        cableColour,
                        baseValue
                      )}
                      container="containerCenter"
                    />
                    <PriceInfo
                      data={this.props.data.delivery}
                      delivery={this.state.delivery}
                      handleRadioButtonFormChange={
                        this.handleRadioButtonFormChange
                      }
                      neonPrice={neonPrice}
                      deliveryPrice={deliveryPrice}
                    />
                    <PaymentInfo />
                    <div className={styles.checkboxConfirmation}>
                      <div>
                        <Checkbox
                          id="rulesAgreement"
                          name="rulesAgreement"
                          state={this.state.rulesAgreement}
                          content={''}
                          method={this.handleCheckboxFormChange}
                        >
                          <span>
                            {ctx('rulesAgreement.textBefore')}
                            <Link
                              text={ctx('rulesAgreement.link')}
                              link="http://arneon.com/regulamin"
                            />
                            {ctx('rulesAgreement.textAfter')}
                          </span>
                        </Checkbox>
                        <CheckboxError
                          state={this.state.rulesAgreement}
                          status={this.state.statusRules}
                        />
                      </div>
                      <div>
                        <Checkbox
                          id="policyAgreement"
                          name="policyAgreement"
                          state={this.state.policyAgreement}
                          content={''}
                          method={this.handleCheckboxFormChange}
                        >
                          <span>
                            {ctx('policyAgreement.textBefore')}
                            <Link
                              text={ctx('policyAgreement.link')}
                              link="http://arneon.com/dane-osobowe"
                            />
                            {ctx('policyAgreement.textAfter')}
                          </span>
                        </Checkbox>
                        <CheckboxError
                          state={this.state.policyAgreement}
                          status={this.state.statusPolicy}
                        />
                      </div>
                      <div>
                        <Checkbox
                          id="saleAgreement"
                          name="saleAgreement"
                          state={this.state.saleAgreement}
                          content={''}
                          method={this.handleCheckboxFormChange}
                        >
                          <span>{ctx('saleAgreement.text')}</span>
                        </Checkbox>
                        <CheckboxError
                          state={this.state.saleAgreement}
                          status={this.state.statusSale}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PerfectScrollbar>
          </div>
        )}
      </I18n>
    );
  }
}
