export const translationData = {
  header: {
    title: 'Powrót do strony ARNEON',
    logo: 'Logo ARNEON',
    link: 'http://arneon.com/'
  },
  input: {
    heading: 'Wprowadź tekst',
    placeholder: 'Wpisz treść neonu',
    desc: 'Niedopuszczalne znaki: \n % ^ * _ = ` ~ [{ }] ; : \\ \' "  |',
    note:
      'Jeśli chcesz, aby Twój neon miał podkład, tekst nie może przekroczyć 110 cm szerokości i 40cm wysokości.'
  },
  font: {
    heading: 'Wybierz font'
  },
  size: {
    heading: 'Wybierz rozmiar',
    image_desc: 'Rozmiar litery'
  },
  colour: {
    heading: 'Wybierz kolor',
    note_on: 'Włączony:',
    note_off: 'Wyłączony:'
  },
  cable: {
    heading: 'Wybierz kolor kabla'
  },
  base: {
    heading: 'Wybierz rodzaj podkładu',
    note_main:
      'Przy zamówieniu otrzymasz wszystkie elementy do samodzielnego montażu (szkło, zasilacz, okablowanie).',
    note_handle:
      'Uchwyt jest przymocowany z tyłu podkładu, jest w nim już zamontowany zasilacz i całość jest gotowa do powieszenia. Przy wyborze uchwytu podkład nie zawiera otworów.',
    note_noHandle:
      'Podkład zamiast uchwytu ma otwory w narożnikach, a zasilacz dostarczany jest osobno do samodzielnego podłączenia. Długość kabli od neonu do zasilacza wynosi 150cm.',
    note_colour: 'Kolor:'
  },
  summary: {
    size: 'Przybliżony rozmiar :',
    height: 'wys.',
    width: 'szer.',
    unit: 'cm',
    currency: 'zł',
    price: 'Cena:',
    delivery: '+ wysyłka',
    button: 'Zamów neon',
    alert:
      'Maksymalne wymiary dla neonu z podkładem to 50cm wysokości i 120cm szerokości. Dostosuj tekst lub skontaktuj się z nami.'
  },
  form: {
    headingForm: 'Zamawiam',
    headingContact: 'Dane kontaktowe',
    headingAddress: 'Adres do wysyłki',
    headingPayment: 'Dane do rachunku',
    name: 'Imię i nazwisko*',
    email: 'Email*',
    phone: 'Numer telefonu*',
    street: 'Ulica*',
    streetNumber: 'Numer domu/mieszkania*',
    postalCode: 'Kod pocztowy*',
    city: 'Miasto*',
    additionalAddress: 'Inny adres do rachunku',
    company: 'Nazwa firmy',
    nip: 'NIP',
    paymentStreet: 'Ulica',
    paymentStreetNumber: 'Numer domu/mieszkania',
    paymentPostalCode: 'Kod pocztowy',
    paymentCity: 'Miasto'
  },
  formSummary: {
    heading: 'Podsumowanie',
    text: 'tekst:',
    font: 'font:',
    size: 'rozmiar:',
    neonColour: 'kolor neonu:',
    cableColour: 'kolor kabla:',
    neonBase: 'podkład:',
    totalPrice: 'Suma',
    price: 'Cena neonu',
    delivery: 'Dostawa:',
    deliveryAddress: 'ul. Piskorzewie 17a, 62-800 Kalisz',
    paymentText: 'Płatność należy dokonać przelewem na konto:',
    bank: 'Santander',
    account: '65 1090 1128 0000 0001 1319 1471',
    companyName: 'AR NEON',
    finalization: 'Zamawiam i płacę'
  },
  rulesAgreement: {
    textBefore: 'Oświadczam, że zapoznałem/am się z ',
    link: 'regulaminem ',
    textAfter: 'strony arneon.com i akceptuję jego treść.*'
  },
  policyAgreement: {
    textBefore: 'Oświadczam, że zapoznałem/am się z ',
    link: 'informacją dotyczącą przetwarzania moich danych osobowych ',
    textAfter: 'w celu zawarcia i wykonania umowy.*'
  },
  saleAgreement: {
    text:
      'Jestem świadomy/a, że neon stanowi rzecz nieprefabrykowaną, produkowaną ściśle według mojej specyfikacji, wobec czego nie przysługuje mi prawo do odstąpienia od umowy sprzedaży (zgodnie z ustawą z dnia 30 maja 2014 r. o prawach konsumenta) i żądanie zwrotu pieniędzy.*'
  },
  response: {
    successHeading: 'Gratulacje!',
    errorHeading: 'Przepraszamy',
    congratulation: 'Zamówienie zostało złożone!',
    thanks:
      'Dziękujemy, że zdecydowałeś/aś się skorzystać z naszych usług. Po otrzymaniu wpłaty rozpoczniemy przygotowanie neonu. Dołożymy wszelkich starań, aby nasz produkt spełnił Twoje oczekiwania.',
    successContact: 'W razie pytań lub wątpliwości prosimy o kontakt:',
    errorContact:
      'Nie udało się złożyć zamówienia. Spróbuj ponownie później lub skontaktuj się z nami:',
    email: 'email: ',
    contactEmail: 'info@arneon.com',
    contactPhone: 'tel: +48 608 371 595 ',
    greetings: 'Z wyrazami szacunku\n Obsługa ',
    greetingsAnchor: 'http://arneon.com/',
    greetingsLink: 'arneon.com',
    button: 'Konfiguruj nowy neon'
  },
  cableColour: {
    white: 'biały',
    black: 'czarny'
  },
  baseMaterials: {
    acrylic: 'akryl',
    aluminium: 'aluminium',
    wood: 'drewno'
  }
};
