import React from 'react';
import classnames from 'classnames';
import SidebarTabs from './SidebarTabs/SidebarTabs';
import InputText from './InputText/InputText';
import TextFont from './TextFont/TextFont';
import TextSize from './TextSize/TextSize';
import TextColour from './TextColour/TextColour';
import CableColour from './CableColour/CableColour';
import TextBase from './TextBase/TextBase';
import OrderSummary from './OrderSummary/OrderSummary';
import Form from './Form/Form';
import ResponseMessage from './ResponseMessage/ResponseMessage';
import styles from './Sidebar.module.scss';
import PropTypes from 'prop-types';

const Sidebar = ({
  currentTab,
  data,
  input,
  selectedFont,
  selectedSize,
  selectedColour,
  selectedCable,
  selectedBase,
  visibleForm,
  confirmationForm,
  serverErrorResponse,
  resetStates,
  handleColourChange,
  handleFontChange,
  handleTabChange,
  handleInputChange,
  neonOrder,
  handleRadioButtonChange,
  handleBaseColourChange,
  handleToggleChange,
  ...baseOptions
}) => {
  const renderComponent = () => {
    switch (currentTab) {
      case 'text':
        return (
          <InputText
            data={data.input}
            input={input}
            handleInputChange={handleInputChange}
          />
        );
      case 'font':
        return (
          <TextFont
            data={data.font}
            selectedFont={selectedFont}
            handleFontChange={handleFontChange}
          />
        );
      case 'size':
        return (
          <TextSize
            data={data.size}
            selectedSize={selectedSize}
            handleRadioButtonChange={handleRadioButtonChange}
          />
        );
      case 'colour':
        return (
          <TextColour
            data={data.colour}
            selectedColour={selectedColour}
            handleColourChange={handleColourChange}
          />
        );
      case 'cable':
        return (
          <CableColour
            data={data.cable}
            selectedCable={selectedCable}
            handleRadioButtonChange={handleRadioButtonChange}
          />
        );
      case 'base':
        return (
          <TextBase
            data={data.base}
            handleRadioButtonChange={handleRadioButtonChange}
            handleBaseColourChange={handleBaseColourChange}
            selectedBase={selectedBase}
            baseOptions={baseOptions}
          />
        );
      default:
        return (
          <InputText data={data.input} handleInputChange={handleInputChange} />
        );
    }
  };
  return (
    <div
      className={classnames(styles.container, visibleForm && styles.openForm)}
    >
      <div className={classnames(confirmationForm && styles.disactive)}>
        <div className={styles.configurator}>
          <SidebarTabs
            currentTab={currentTab}
            handleTabChange={handleTabChange}
          />
          {renderComponent()}
        </div>
        <OrderSummary
          input={input}
          neonOrder={neonOrder}
          selectedBase={selectedBase}
          visibleForm={visibleForm}
          handleToggleChange={handleToggleChange}
        />
      </div>
      <div
        className={classnames(
          styles.form,
          visibleForm && !confirmationForm && styles.active,
          confirmationForm && styles.disactive
        )}
      >
        {input && (
          <Form
            data={data}
            input={input}
            selectedBase={selectedBase}
            selectedFont={selectedFont}
            selectedSize={selectedSize}
            selectedColour={selectedColour.neonLabel}
            selectedCable={selectedCable}
            neonPrice={neonOrder.neonPrice}
            deliveryPrice={neonOrder.delivery}
            baseOptions={baseOptions}
            neonOrder={neonOrder}
            visibleForm={visibleForm}
            confirmationForm={confirmationForm}
            handleToggleChange={handleToggleChange}
          />
        )}
      </div>
      <div
        className={classnames(
          styles.successContainer,
          serverErrorResponse && styles.active,
          confirmationForm && styles.active,
          resetStates && styles.disactive
        )}
      >
        {visibleForm && (
          <ResponseMessage
            serverErrorResponse={serverErrorResponse}
            resetStates={resetStates}
            handleToggleChange={handleToggleChange}
          />
        )}
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  visibleForm: PropTypes.bool,
  resetStates: PropTypes.bool,
  currentTab: PropTypes.string,
  data: PropTypes.object,
  input: PropTypes.string,
  selectedFont: PropTypes.string,
  selectedSize: PropTypes.string,
  selectedColour: PropTypes.objectOf(PropTypes.string),
  selectedCable: PropTypes.string,
  selectedBase: PropTypes.string,
  handleColourChange: PropTypes.func,
  handleFontChange: PropTypes.func,
  handleTabChange: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleRadioButtonChange: PropTypes.func,
  handleBaseColourChange: PropTypes.func,
  handleToggleChange: PropTypes.func,
  neonOrder: PropTypes.objectOf(PropTypes.number),
  baseOptions: PropTypes.objectOf(PropTypes.string)
};

export default Sidebar;
