import React from 'react';
import { I18n } from '../../../Translations/I18n';
import InfoBox from '../../../InfoBox/InfoBox';
import RadioButtonGroup from '../../../RadioButtonGroup/RadioButtonGroup';
import PropTypes from 'prop-types';

const BaseHandle = ({ data, state, method }) => {
  return (
    <React.Fragment>
      <RadioButtonGroup
        data={data}
        name="baseHandle"
        state={state}
        method={method}
      />
      {state === 'handleWithPower' ? (
        <I18n>{ctx => <InfoBox ctx={ctx('base.note_handle')} />}</I18n>
      ) : <I18n>{ctx => <InfoBox ctx={ctx('base.note_noHandle')} />}</I18n>}
    </React.Fragment>
  );
};

BaseHandle.propTypes = {
  data: PropTypes.array,
  material: PropTypes.string,
  state: PropTypes.string,
  method: PropTypes.func
};

export default BaseHandle;
