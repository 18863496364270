import React from 'react';
import { TranslationsContext } from './TranslationsContext';

export const I18n = ({ children }) => (
  <TranslationsContext.Consumer>
    {translations => {
      return children(id => {
        const translationKeys = id.split('.');
        const mainKey = translationKeys[0];
        const subKey = translationKeys[1];
        return translations[mainKey][subKey];
      });
    }}
  </TranslationsContext.Consumer>
);
