import React from 'react';
import classnames from 'classnames';
import styles from './ButtonConfirmation.module.scss';
import PropTypes from 'prop-types';

const ButtonConfirmation = ({
  type,
  input,
  content,
  name,
  value,
  handleToggleChange
}) => {
  return (
    <button
      type={type || 'button'}
      className={classnames(styles.default, !input && styles.disabled)}
      disabled={!input}
      name={name || ''}
      value={value}
      onClick={e => handleToggleChange(e.currentTarget)}
    >
      {content}
    </button>
  );
};

ButtonConfirmation.propTypes = {
  type: PropTypes.string,
  input: PropTypes.string,
  content: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  handleToggleChange: PropTypes.func
};

export default ButtonConfirmation;
