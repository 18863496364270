import React, { memo } from 'react';
import { ReactComponent as TextIcon } from '../../assets/images/text.svg';
import { ReactComponent as FontIcon } from '../../assets/images/font.svg';
import { ReactComponent as SizeIcon } from '../../assets/images/size.svg';
import { ReactComponent as ColourIcon } from '../../assets/images/colour.svg';
import { ReactComponent as CableIcon } from '../../assets/images/cable.svg';
import { ReactComponent as BaseIcon } from '../../assets/images/underlay.svg';
import { ReactComponent as NeonCloud } from '../../assets/images/underlay_icon1.svg';
import { ReactComponent as NeonRectangle } from '../../assets/images/underlay_icon2.svg';
import { ReactComponent as FontSize } from '../../assets/images/size_icon.svg';
import { ReactComponent as TurnOffIcon } from '../../assets/images/turn_off.svg';
import { ReactComponent as Loader } from '../../assets/images/loader.svg';
import PropTypes from 'prop-types';

const icons = {
  text: <TextIcon />,
  font: <FontIcon />,
  size: <SizeIcon />,
  colour: <ColourIcon />,
  cable: <CableIcon />,
  base: <BaseIcon />,
  cloud: <NeonCloud />,
  rectangle: <NeonRectangle />,
  fontSize: <FontSize />,
  light: <TurnOffIcon />,
  loader: <Loader />
};

const Icon = memo(({ src }) => {
  return icons[src];
});

Icon.propTypes = {
  component: PropTypes.string
};

export default Icon;
